import React, { useEffect, useState } from "react";

function Question({ questionBody, help }) {
  const [currentAnswer, setCurrentAnswer] = useState("");

  useEffect(() => {
    setCurrentAnswer("");
  }, [questionBody]);

  const handleOptionChange = (i) => {
    setCurrentAnswer(i);
    if (i + 1 !== questionBody.correct_answer) {
      const diffQuestions = localStorage.getItem('diffQuestions');
      if (diffQuestions !== null) {
        localStorage.setItem('diffQuestions', JSON.stringify([...JSON.parse(diffQuestions), questionBody]))
      }
      else {
        localStorage.setItem('diffQuestions', JSON.stringify([questionBody]))
      }
    }
  };

  return (
    <div className="question">
      <p>
        {questionBody?.question.replace(/^\d{1,2}\.\d{1,3}\s/, "")}
        {questionBody?.image && <><br/><img src={require(`../assets/images/questions/${questionBody.image}`)}/></>}
      </p>
      <ul>
        {questionBody?.answers.map((el, i) => (
          <li key={i}>
            <input
              checked={currentAnswer === i}
              onChange={() => handleOptionChange(i)}
              value={i}
              type="radio"
              name="question"
              id={i}
            />
            <label style={(help && questionBody.correct_answer - 1 === i) || (currentAnswer === i && i === questionBody.correct_answer - 1) ? { fontWeight: 'bold' } : { fontWeight: 'normal' }} htmlFor={i}>{el}</label>
          </li>
        ))}
      </ul>
      <div className={currentAnswer === "" ? "hidden" : ""}>{currentAnswer + 1 === questionBody?.correct_answer ? "Верно" : "Ошибка"}</div>
    </div>
  );
}

export default Question;
