import { useEffect, useState } from "react";
import CommandPanel from "./components/CommandPanel";
import Question from "./components/Question";
import "./index.css";

const data = require("./ответы.json");
function App() {
  const getChapters = () => {
    let resArr = [];
    data.forEach((item) => {
      const chapter = item.question.match(/^\d{1,2}/g)[0];
      !resArr.includes(chapter) && resArr.push(chapter)
    });
    return resArr;
  }
  const getQuestionByChapter = (chapter) => {
    setCurrentQuestion(0);
    if (+chapter === 0) {
      setQuestions(data);
      return;
    }
    if (+chapter === -1) {
      setQuestions(local);
      return;
    }
    let resArr = [];
    data.forEach((item) => {
      const chapterItem = item.question.match(/^\d{1,2}/g)[0];
      chapter === chapterItem && resArr.push(item)
    });
    setQuestions(resArr);
  }

  const [time, setTime] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [correctAnswer, setCorrectAnswer] = useState(data[0].correct_answer);
  const [local, setLocal] = useState(JSON.parse(localStorage.getItem('diffQuestions')));
  const [isShowParams, setShowParams] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checkedHelp, setCheckedHelp] = useState(false);
  const [chapters, setChapters] = useState(getChapters());
  const [questions, setQuestions] = useState(data);
  const [currentChapter, setCurrentChapter] = useState(0);

  useEffect(()=>{
    setLocal(JSON.parse(localStorage.getItem('diffQuestions')))
  }, [currentQuestion])

  useEffect(() => {
    const timer = setInterval(() => setTime(time + 1), 1000);
    setSeconds(time - 60 * Math.floor(time / 60));
    setMinutes(Math.floor(time / 60) - 60 * Math.floor(time / 60 / 60));
    setHours(Math.floor(time / 60 / 60) - 24 * Math.floor(time / 60 / 60 / 24));
    return () => clearInterval(timer);
  }, [time]);

  const checkHandle = () => {
    if (!checked) {
      let shuffled = [].concat(questions);
      setQuestions(shuffled.sort(() => Math.random() - 0.5));
    }
    else {
      getQuestionByChapter(currentChapter);
    }
    setChecked(!checked)
  }

  const changeSelect = (e) => {
    setCurrentChapter(e.target.value)
    getQuestionByChapter(e.target.value)
  }
  return (
    <div className="cont">
      <button onClick={()=>setShowParams(!isShowParams)} className="exam">Х</button>
      <div className={isShowParams ? "exam_param" : "exam_param hidden"}>
        <label>
          <input type="checkbox" onChange={checkHandle} checked={checked} />
          Режим экзамена
        </label>
        <br/>
        <label>
          <input type="checkbox" onChange={()=>setCheckedHelp(!checkedHelp)} checked={checkedHelp} />
          Режим обучения
        </label>
        <br/>
        <button onClick={()=> localStorage.removeItem('diffQuestions') }>Очистить список сложных вопросов</button>
        <br/>
        <select onChange={(e) => changeSelect(e)} name="mode">
          <option value="0">Марафон</option>
          {chapters.map((el, i) => <option key={i} value={el}>{el}</option>)}
          {localStorage.getItem('diffQuestions') && <option value="-1">Сложные вопросы ({JSON.parse(localStorage.getItem('diffQuestions')).length})</option>}
        </select>
      </div>
      <p className="timer">
        {hours < 10 ? `0${hours}` : hours}:
        {minutes < 10 ? `0${minutes}` : minutes}:
        {seconds < 10 ? `0${seconds}` : seconds}
      </p>
      <div className="question_panel">
        {
          questions.map((el, i) => <div className={currentQuestion === i && "sel"} onClick={()=>{setCurrentQuestion(i); setCorrectAnswer(el.correct_answer)}} key={i}>{i + 1}</div>)
        }
      </div>
      <Question questionBody={questions[currentQuestion]} help={checkedHelp}/>
      <CommandPanel/>
    </div>
  );
}

export default App;
