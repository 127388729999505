import React from 'react'
import done from'../assets/images/done.png';

function CommandPanel({checkAnswer}) {
  return (
    <div className='command_panel'>
        <button onClick={checkAnswer}>Завершить</button>
    </div>
  )
}

export default CommandPanel